<template>
  <div>
    <b-container class="mt-5">
      <div>
        <h2 class="font-weight-bolder text-colorBlue text-center">
          {{ department.name }}
        </h2>
        <h4 class="pt-2 font-weight-bolder text-colorGreen">
          {{ department.intro }}
        </h4>

        <!-- Inline description with Read More / Read Less functionality -->
        <div class="pt-50 text-colorText">
          <span>
            <span
              v-html="
                isTruncated ? truncatedDescription : department.description
              "
            ></span
            ><a
              @click="toggleDescription"
              class="read-more text-colorBlue font-weight-bolder"
              style="cursor: pointer; text-decoration: underline"
            >
              {{ isTruncated ? " Read More" : " Read Less" }}
            </a>
          </span>
        </div>
      </div>

      <!-- Your existing code continues -->
      <div class="mt-2 p-5 my-bg">
        <h3 class="pt-2 text-center font-weight-bolder text-colorBlue">
          Meet Our Expert Healthcare Team
        </h3>
        <b-row class="mt-3 d-flex justify-content-center">
          <b-col
            md="3"
            class="d-flex align-items-stretch justify-content-center mb-1"
            style="position: relative"
            v-for="(doctor, index) in displayedDoctors"
            :key="index.id"
          >
            <div class="doctor-card d-flex flex-column">
              <div
                class="doctor-image-container"
                v-if="doctor && doctor.profile_image"
              >
                <img
                  :src="doctor.profile_image"
                  alt="Doctor Image"
                  style="width: 100%; height: 300px"
                />
              </div>
              <div v-else>
                <span>No profile image available</span>
              </div>

              <div class="bg-colorBlue py-1 px-2 doctor-card">
                <div class="h-75">
                  <h4 class="font-weight-bolder text-white">
                    {{ doctor.name }}
                  </h4>
                  <h5 class="text-white font-weight-normal multi-line-truncate">
                    {{ doctor.intro }}
                  </h5>
                </div>
                <div>
                  <b-button
                    @click="openProfileModal(doctor)"
                    class="mt-1"
                    variant="light"
                    >View Details</b-button
                  >
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <div class="text-center mt-3 mb-3">
          <b-button
            v-if="currentPage < totalPages"
            @click="showMore"
            class="mr-2"
            variant="colorGreen"
            size="md"
          >
            Show More
          </b-button>
          <b-button
            v-if="currentPage > 1"
            @click="showLess"
            variant="colorBlue"
            size="md"
          >
            Show Less</b-button
          >
        </div>

        <doctor-view-modal
          :doctor="selectedDoctor"
          @modalClosed="closeProfileModal"
          :key="`view-${DoctorViewModal}`"
        />
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DoctorViewModal from "@/components/doctorProfile/DoctorProfileModal.vue";

export default {
  mixins: [util],
  components: {
    ToastificationContent,
    DoctorViewModal,
  },
  props: {
    department: Object,
  },
  data() {
    return {
      doctors: [],
      displayedDoctors: [],
      procedures: null,
      showModal: false,
      selectedDoctor: null,
      DoctorViewModal: 0,
      viewAll: false, // Controls the view for doctors
      isTruncated: true, // Controls if the description is truncated
      currentPage: 1,
      totalPages: 0,
      itemsPerPage: 5,
    };
  },
  computed: {
    ...mapState({
      cart: (state) => state.appData.cart, // Assuming your cart is stored in appData module of Vuex
    }),
    // visibleDoctors() {
    //   // Check if doctors is defined and is an array
    //   if (this.doctors && Array.isArray(this.doctors)) {
    //     return this.viewAll ? this.doctors : this.doctors.slice(0, 4);
    //   }
    //   // Return an empty array if doctors is not defined
    //   return [];
    // },
    truncatedDescription() {
      // Assuming you want to truncate the description to approximately 2 lines based on character count
      const maxChars = 150; // Adjust this value based on how much text fits into 2 lines
      if (
        this.department.description &&
        this.department.description.length > maxChars
      ) {
        return this.department.description.slice(0, maxChars) + "...";
      }
      return this.department.description;
    },
  },
  methods: {
    ...mapActions({
      getdoctors: "appData/getDoctorsPublic",
      getprocedures: "appData/getProceduresPublic",
      addToCart: "appData/addToCart",
      removeFromCart: "appData/removeFromCart",
    }),
    openProfileModal(doctor) {
      this.DoctorViewModal += 1;
      this.selectedDoctor = doctor;
      this.$nextTick(() => {
        this.$bvModal.show("doctor-view-modal");
      });
    },
    async fetchData() {
      try {
        const res_doctors = await this.getdoctors({
          department: this.department.id,
          pageNumber: this.currentPage,
        });

        // Update the total pages (assuming your API returns this)
        this.totalPages = res_doctors.data.total_pages || this.totalPages;

        // Concatenate new doctors to the existing list
        const newDoctors = res_doctors.data.results;

        if (this.currentPage === 1) {
          // On initial load, set displayedDoctors to the fetched data
          this.doctors = newDoctors;
        } else {
          // Append new doctors to the existing array
          this.doctors = [...this.doctors, ...newDoctors];
        }

        // Update displayedDoctors to show the accumulated doctors
        this.displayedDoctors = this.doctors;
      } catch (error) {}
    },
    // addCart(item) {
    //   const cartItemId = `${item.id}_${this.cartItemType.TREATMENT}`;
    //   const isInCart = this.cart.some((cartItem) => cartItem.id === cartItemId);

    //   if (!isInCart) {
    //     this.addToCart({
    //       id: cartItemId,
    //       name: item.name,
    //       departmentName: this.department.name,
    //       itemId: item.id,
    //       price: item.amount,
    //       type: this.cartItemType.TREATMENT,
    //       quantity: 1,
    //     });
    //     this.$toast(
    //       {
    //         component: ToastificationContent,
    //         props: {
    //           title: "Item added to cart successfully",
    //           variant: "success",
    //           icon: "BellIcon",
    //         },
    //       },
    //       {
    //         position: "top-right",
    //       }
    //     );
    //   }
    // },
    // handleCartAction(item) {
    //   if (this.isInCart(item)) {
    //     this.$router.push({ name: "Cart" });
    //   } else {
    //     this.addCart(item);
    //   }
    // },
    // isInCart(item) {
    //   const cartItemId = `${item.id}_${this.cartItemType.TREATMENT}`;
    //   return this.cart.some((cartItem) => cartItem.id === cartItemId);
    // },
    closeProfileModal() {
      this.selectedDoctor = null;
    },
    toggleViewAll() {
      this.viewAll = !this.viewAll;
    },
    toggleDescription() {
      this.isTruncated = !this.isTruncated;
    },
    showMore() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchData(); // Fetch next page and add to existing list
      }
    },

    showLess() {
      if (this.currentPage > 1) {
        this.currentPage--;
        // Update displayedDoctors without fetching new data
        this.displayedDoctors = this.doctors.slice(
          0,
          this.currentPage * this.itemsPerPage
        );
      }
    },
  },

  watch: {
    "department.id": {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          // Reset currentPage and displayedDoctors when department changes
          this.currentPage = 1;
          this.displayedDoctors = [];

          // Fetch data for the new department
          this.fetchData();
        }
      },
    },
  },
  // beforeRouteUpdate(to, from, next) {
  //   // Fetch new data when the route changes
  //   this.fetchData();
  //   next();
  // },

  async mounted() {
    this.currentPage = 1;
    this.fetchData();
  },
};
</script>

<style scoped>
.my-bg {
  background: url("~@/assets/images/treatment/overlay.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.bg-colorBlue:hover {
  background-color: #06bd46; /* Change to your desired green color */
}
.hover-img {
  display: none;
}
.image-container-hover:hover .hover-img {
  display: block;
}

.image-container-hover:hover .base-img {
  display: none;
}
.circleBase {
  border-radius: 50%;
}

.bg-colorDoctor {
  background-color: #fcfcfc41; /* Change to your desired green color */
  color: black;
}

.bg-colorDoctor:hover {
  background-color: #0c1950; /* Change to your desired green color */
  color: white;
}
.border-top {
  border-top: 1px solid white;
  padding-top: 20px;
  padding-bottom: 20px;
}
.multi-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; /* Adjust line height if needed */
  max-height: calc(1.5em * 3); /* 1.5em * number of lines to show */
}
.doctor-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px; /* Adjust max-width as needed */
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.doctor-image-container {
  flex: 1;
}
</style>
