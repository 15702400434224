<template>
  <div
    :style="`background: url(${department.display_image});`"
    class="my-bg d-flex align-items-center py-2"
  >
    <b-container>
      <div class="py-3">
        <div>
          <h1 class="font-weight-bolder text-colorBlue">
            {{ department.name }}
          </h1>
        </div>
        <div class="pt-2">
          <h4 class="font-weight-normal text-colorGreen">
            Most Extensive & Affordable Medical Procedures
          </h4>
        </div>
        <div class="pt-2 d-flex justify-content-start">
          <b-button
            pill
            variant="colorGreen"
            size="md"
            class="first-button text-light font-weight-bolder mr-2 mb-1 mb-xs-0"
            @click="contactPage"
          >
            <img
              src="@/assets/images/icons/button2.svg"
              class="logo mr-50"
              alt="image"
            />
            Contact Us
          </b-button>
          <b-button
            pill
            variant="colorGreen"
            size="md"
            class="first-button text-light font-weight-bolder mr-2 mb-1 mb-xs-0"
            @click="getQuote"
          >
            <img
              src="@/assets/images/icons/button1.svg"
              class="mr-50"
              alt="image"
            />Get a Quote
          </b-button>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    department: Object,
  },
  methods: {
    contactPage() {
      this.$router.push({ name: "ContactUs" });
    },
    getQuote() {
      this.$router.push({ path: "/", hash: "#getQuote" });
    },
  },
};
</script>

<style scoped>
.my-bg {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-blend-mode: multiply;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.button-container .first-button:hover .logo {
  content: url("~@/assets/images/icons/medical-cross-stroke.svg");
}
</style>
